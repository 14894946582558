<template>
  <div class="plateformOrganizeList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :exportUrl="exportURL"
      :exportMethod="'get'"
      :searchUrl="searchUrl"
      :searchParams="searchParams"
      :extraParams="extraParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
      </template>
      <template #tableLeftSlot>
        <div class="table-left" v-show="treeShow">
          <!-- 搜索框 -->
          <div class="tree-search">
            <v-input class="search-input" :width="160" v-model="filterText" prefix-icon="el-icon-search" placeholder="请输入名称" />
            <v-button text="新增" class="add-button" permission="addTreeNode" @click="createOrg" />
          </div>
          <!-- 控件树 -->
          <div class="tree-group">
            <button-tree
              ref="buttonTree"
              v-loading="treeLoading"
              :key="keyTree"
              :data="treeData"
              :defaultProps="treeProps"
              :btnOps="btnOps"
              :filterText="filterText"
              :filterNodeMethod="filterNodeMethod"
              :defaultExpandedKeys="defaultExpandedKeys"
              :currentNodeKey="currentNodeKey"
              :highlightCurrent="true"
              :expand-on-click-node="false"
              @buttonClick="buttonNode"
              @nodeClick="nodeClick"
            />
          </div>
        </div>
        <div class="treeSwith" @click="treeSwith">
          <img v-if="treeShow" :src="treeOffImgSrc" />
          <img v-if="!treeShow" :src="treeOnImgSrc" />
        </div>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.userName" placeholder="请输入姓名" />
        <v-input label="手机号" v-model="searchParams.mobileNum" placeholder="请输入手机号" />
        <v-input label="工号" v-model="searchParams.jobNum" placeholder="请输入工号" />
        <v-select label="性别" placeholder="请选择性别" v-model="searchParams.sex" :options="sexOps" />
        <v-select
          label="账号类型"
          v-model="searchParams.roleType"
          :options="roleTypeFilterOps"
        />
        <template v-if="searchParams.roleType != '107' && searchParams.roleType">
          <v-select2
            label="管理范围"
            v-model="searchParams.organizeId"
            :subjoin="roleExtraParams"
            v-bind="organizeParams"
          />
          <v-select2
            label="角色类型"
            v-model="searchParams.roleId"
            :subjoin="rolesExtraParams"
            v-bind="roleParams"
          />
        </template>
        <v-select label="账号状态" v-model="searchParams.accountStatus" :options="statusOps" />
      </template>
      <template #operateSlot="scope">
        <v-button permission="edit" text="个人详情" type="text" @click="edit(scope.row)" />
        <el-dropdown trigger="click" v-if="scope.row.accountStatus != 2 ">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" v-if="scope.row.accountStatus != 2">
            <el-dropdown-item>
              <v-button text="设置账号" type="text" @click="setAccount(scope.row)"/>
            </el-dropdown-item>
            <el-dropdown-item>
              <v-button text="业务职责" v-if="treeType == 2" permission="responsibility" type="text" @click="responsibility(scope.row)" />
            </el-dropdown-item>
            <el-dropdown-item>
              <v-button text="关闭成员"  permission="delete" type="text" @click="close(scope.row)" />
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量关闭" permission="batchDelete" @click="batchRemove(scope.selectedData)"></v-button>
        <v-button text="批量移动" permission="batchMove" @click="batchMove(scope.selectedData)"></v-button>
      </template>
    </list>
    <el-dialog class="dialog-tree__node" :title="dialogText" :visible.sync="dialogVisible" width="666px">
      <el-form ref="form" label-width="100px" :model="form" :key="key">
        <!-- 新增成员 设置账号 -->
        <template v-if="dialogValue === 'component'">
          <component :is="currentComponent" :userData="userData" :currentData="currentData" :treeType="treeType"  @saveSuccess="saveSuccess"></component>
        </template>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRoleTypeURL,
  getOrganizeURL,
  getEnterOrgTreeListURL,
  selectEnterpriseUserInfoListNewURL,
  getRolesURL,
  exportURL,
  batchURL,
} from './api';
import {
  regionTypeOps,
  sexOps,
  sexMap,
  statusOps,
  statusMap,
  btnOps,
} from './map';
import { ButtonTree, SelectTree } from 'components/bussiness/index'
import treeOnImgSrc from './img/treeOn.png'
import treeOffImgSrc from './img/treeOff.png'
import accountForm from 'views/common/plateformOrganize/components/accountForm.vue'
import itemVue from '../../../components/bussiness/col2-detail/item.vue';

export default {
  name: 'plateformOrganizeList',
  components: {
    'button-tree': ButtonTree,
    'select-tree': SelectTree,
    accountForm,
  },
  data () {
    return {
      width: 320,
      exportURL,
      searchUrl: selectEnterpriseUserInfoListNewURL,
      regionTypeOps,
      sexOps: sexOps(1),
      sexMap,
      statusOps: statusOps(1),
      statusMap,
      roleTypeOps: [],
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
      searchParams: {
        userName: '',
        mobileNum: '',
        jobNum: '',
        sex: undefined,
        roleType: undefined,
        organizeId: '',
        roleId: undefined,
        orgId: ''
      },
      extraParams: {
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'sex',
          label: '性别',
          formatter (row) {
            return sexMap[row.sex]
          }
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'parentOrgName',
          label: '所属组织'
        },
        {
          prop: 'jobNum',
          label: '工号'
        },
        {
          prop: 'adminDuty',
          label: '职务'
        },
        {
          prop: 'jobRank',
          label: '职级'
        },
        {
          prop: 'orgName',
          label: '管理范围'
        },
        {
          prop: 'roleName',
          label: '角色'
        },
        {
          prop: 'accountStatus',
          label: '账号状态',
          formatter (row) {
            return statusMap[row.accountStatus]
          }
        }
      ],
      type2: 0, // currentNodeKey 改变的方式 1 表示组件更新时改变
      keyTree: 0,
      treeLoading: false,
      currentData: null, // 按钮点击时保存的节点数据  最新一次点击的树节点信息
      currentItem: null, // 按钮点击时保存的按钮数据
      treeOnImgSrc,
      treeOffImgSrc,
      treeShow: true,
      filterText: '',
      btnOps,
      treeData: [],
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      treeOrgData: {}, // select-button值对应的数据
      currentNodeKey: '',
      defaultExpandedKeys: [],
      treeType: undefined,
      // 弹框
      ids: [], // 记录批量移动人员的ids
      loading: false,
      key: 0,
      form: {
        orgId: '',
        orgName: '',
        parentId: ''
      },
      dialogValue: '',
      dialogText: '',
      dialogVisible: false,
      userData: {},
      currentComponent: '',
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    roleExtraParams () { // 管理范围额外参数
      return {
        roleType: this.searchParams.roleType
      }
    },
    rolesExtraParams () { // 角色
      let organizeId = ''
      if (this.userInfo.userType === '101') {
        organizeId = this.userInfo.orgId
      }
      return {
        roleType: this.searchParams.roleType,
        organizeId: organizeId || this.searchParams.organizeId
      }
    },
    roleTypeFilterOps () {
      // 2 租户组织 只允许选择公司项目  1 平台职能部门 只能选 集团+系统 项目
      // 社区,账号只能选集团以下的角色, 
      let ops = this.roleTypeOps
      if (Number(this.treeType) === 1) {
        ops = this.roleTypeOps.filter(v => v.value === '100' || v.value === '106')
      } else if (Number(this.treeType) === 2) {  
        ops = this.roleTypeOps.filter(v => v.value === '101' ||  v.value === '102')
      } else if ( Number(this.treeType) === 3) {
        ops = this.roleTypeOps.filter(v => v.value === '104' ||  v.value === '105')
      }
      let opsAll = [{ text: '全部', value: undefined }, ...ops]
      return opsAll
    }
  },
  watch: {
    dialogVisible (val) {
      this.key += 1
      if (!val) {
        // 弹窗关闭时，需要调一次列表接口
        this.form = {
          orgId: '',
          parentId: '',
          orgName: ''
        }
      }
    },
    currentNodeKey (val, old) {
      // 1. 获取当前选中的key
      this.$set(this.searchParams, 'orgId', val)
      // 2. 弹框状态和该组件更新时导致 currentNodeKey 的改变不触发查询列表
      if (!this.dialogVisible && this.type2 !== 1) {
        this.$refs.list.searchData(this.searchParams)
      }
      this.type2 = 0
    }
  },
  created () {
    this.getRoleType()
    // 租户组织 2 平台组织 1
    this.treeType = 3;
  },
  mounted () {
    const orgTree = sessionStorage.getItem('orgTree')
    if (orgTree) {
      this.type2 = 1 // 当组件更新导致的 currentNodeKey 变化,不触发列表的查询
      const { orgId, orgParentId, orgType } = JSON.parse(orgTree)
      this.defaultExpandedKeys = orgParentId ? [String(orgParentId)] : []
      this.currentNodeKey = String(orgId)
      this.searchParams.orgId = String(orgId) // 默认调取一次列表接口时，参数确保正确
      this.treeType = orgType
      sessionStorage.removeItem('orgTree')
    }
    this.getTreeList()
    this.$setBreadcrumb('管理组织')
  },
  methods: {
    createOrg () { // 新增
      // 跳转到新增商家页面
      this.$router.push({
        name: 'shopMgrForm',
      })
    },

    //#region 左下角 批量操作
    async batchRemove ({ data }, status = 0) { // 批量关闭
      const ids = data.map(v => v.userId)
      if (ids.length === 0) {
        this.$message('请选择成员')
        return
      }
      let isOk = await this.$confirm(`确认关闭所选成员共${ids.length}人？`)
      let _ids = ids.join(',')
      let dataArr = []
      data.forEach(item => {
        dataArr.push(`${item.userName}-${item.loginName}`)
      })
      isOk && this.updateStatus(_ids, status, dataArr)
    },
    batchMove ({ ids }) { // 批量移动
      this.ids = ids
      if (ids.length === 0) {
        this.$message('请选择成员')
        return
      }
      this.dialogVisible = true
      this.dialogValue = 6
    },
    //#endregion
    //#region 列表操作按钮 区间
    edit (row) { // 编辑按钮
      this.$router.push({
        name: 'memberInfoForm',
        query: {
          id: row.userId,
          newId: row.id,
          orgId: row.orgId,
          orgParentId: row.parentOrgId,
          orgType: this.treeType
        }
      })
    },
    async close (row, status = 0) { // 关闭按钮
      let msg = '确认关闭该成员账号？'
      let isOk = await this.$confirm(msg)
      isOk && this.updateStatus(row.userId, status, [`${row.userName}-${row.loginName}`])
    },
    // 职责
    responsibility (row) {
      this.$router.push({
        name: 'responsibilityForm',
        query: {
          orgId: row.orgId,
          userId: row.userId,
          userName: row.userName,
          mobileNum: row.mobileNum
        }
      })
    },
    updateStatus (ids, status, dataArr) { // 关闭账号接口
      // 操作日志添加操作对象 规则：角色名称-归属层级
      let dataObject = dataArr.join(';')

      let _this = this
      let postData = {
        userIds: ids.split(','),
        status, // 0 关闭 1 开启
        dataObject: dataObject
      }
      _this.$axios({
        method: 'put',
        url: batchURL,
        data: postData
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    //#endregion 列表操作结束
    /** 账号类型 */
    getRoleType () { // 账号类型 100/101/102...
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === '100') {
          const { data } = res
          // TODO 当前没有区域角色
          console.log('roleType', res);
          this.roleTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id
            }
          })
        }
      })
    },
    /** 树节点组织数据 */
    getTreeList () { // 获取树节点数据
      this.treeLoading = true
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.treeType}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            // TODO 后端返回树组织每一层级的按钮列表
            this.treeData = res.data
            this.keyTree += 1
          }
        }).finally(() => {
          this.treeLoading = false
        })
    },
    treeSwith () { // 树的显示隐藏
      this.treeShow = !this.treeShow
    },
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    buttonNode (data, item) { // 点击树节点的弹框按钮
      // data 为节点对象的数据, item 为按钮的对象数据
      this.currentData = data
      this.currentItem = item
      if (item.type === 'component') {
        this.setComponent(item);
        return
      }
      this.toJumpRoute(data, item)
    },
    setComponent (item) {
      const { componentName, value } = item;
      switch (value) {
        case 'addMemberBtn': 
        // 新增按钮点击，重置用户数据
          this.userData = {};
        case 'editMemberBtn':
        this.showDialog(item);
      }
      this.currentComponent = componentName;
      // TODO 当前使用的逻辑是以当前选中的组织id 作为后期操作的parentId 以前就这样
    },
    showDialog (config) {
      const { title, type } = config;
      this.dialogText = title;
      this.dialogValue = type;
      this.dialogVisible = true;
    },
    // 设置账号
    setAccount (row) {
      // 设置账号设置用户默认数据
      this.userData = row;
      this.setComponent({
        text: '设置账号',
        title: '设置账号',
        type: 'component',
        componentName: 'accountForm',
        value: 'editMemberBtn'
      })
    },
    toJumpRoute (data, item) { // 跳转路由
      switch (item.value) {
        case 'addStoreBtn':  // 新增门店
          this.$router.push({
            name: 'storeManagementForm',
            query: {
              shopId: data[this.treeProps.parentId]
            }
          })
        break;
        case 'editStoreBtn':  // 编辑门店
          this.$router.push({
            name: 'storeManagementForm',
            query: {
              id: data[this.treeProps.id]
            }
          })
        break;
        case 'editBusinessBtn':  //编辑商家
          this.$router.push({
            name: 'shopMgrForm',
            query: {
              shopId: data[this.treeProps.id]
            }
          })
        break;
        default: 
        break;
      }
    },
    nodeClick (data, node, nodeVue) { // 点击树节点文字
      /* data 节点数据对象, node 节点的vue虚拟对象, nodeVue 节点组件本身 */
      const val = data && (data[this.treeProps.id] || undefined)
      this.defaultExpandedKeys = val ? [val] : []
      this.currentNodeKey = val
    },
    saveSuccess () {
      // 弹框消失，刷新列表
      this.dialogVisible = false;
      this.$refs.list.searchData();
    },
  }
}
</script>
<style lang="scss">
.plateformOrganizeList-wrapper {
  .table-left {
    height: 100%;
    display: inline-block;
    position: relative;
    .tree-search {
      text-align: center;
      display: flex;
      ::v-deep .v-control {
        // width: 100%;
        flex: 1;
        .el-input {
          // width: 100% !important;
          flex: 1;
          .el-input__inner {
            outline: none;
            padding: 2px 10px 2px 30px;
            height: 33px;
          }
          .el-input__icon {
            line-height: 33px;
          }
          .el-input__suffix {
            line-height: 33px;
          }
        }
      }
      .add-button {
        margin-left: 8px;
        ::v-deep .el-button{
          padding: 0 8px;
        }
      }
    }
    ::v-deep .el-loading-mask {
      z-index: 100!important;
    }
    .tree-select {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      top: 38px;
      left: 0;
      display: flex;
      z-index: 200;
      .select-button {
        width: 100%;
        // height: 100%;
        flex: 1;
        ::v-deep .el-button {
          padding: 0 8px;
          text-align: left;
          color: #414142;
          background-color: #e3e5e9;
          border-color: #e8e9eb;
          i {
            float: right;
          }
        }
      }
      .select {
        ::v-deep .el-button {
          background: linear-gradient(90deg, #43a8ff 0%, #529fff 99%);
          color: #fff;
        }
      }
      .add-button {
        margin-left: 8px;
        ::v-deep .el-button{
          padding: 0 8px;
        }
      }
      .tree-select__list {
        position: absolute;
        top: 30px;
        left: 0;
        text-align: left;
        display: inline-block;
        width: 100%;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        z-index: 200;
        > li {
          cursor: pointer;
          padding: 0px 12px;
          color: #909399;
          &:hover {
            color: #1B8CF2;
            background-color: #f5f7fa;
          }
        }
      }
    }
    .tree-group {
      margin-top: 40px;
      height: calc(100% - 73px);
      width: 240px;
    }
    .treeSwith {
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 100%;
      vertical-align: top;
      width: 36px;
      img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 36px;
        height: 80px;
      }
      &-bar {
        width: 8px;
        height: 160px;
        background: linear-gradient(
          to top left,
          rgba(228, 228, 228, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .dialog-tree__node {
    text-align: left;
    .p-text {
      margin-top: 0;
    }
    .p-mar-0 {
      margin: 0;
    }
    .p-pad-l {
      padding-left: 45px;
    }
    .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}
.mx-datepicker-popup{
  z-index: 9999;
}
</style>
